/** @jsx jsx */
import { jsx, Container, Styled } from "theme-ui"
import { useState } from "react"
import { useTrail } from "react-spring"
import Layout from "./layout"
import { ChildImageSharp } from "../types"
import ProjectItem from "../../../../node_modules/@lekoarts/gatsby-theme-emma/src/components/project-item"

type ProjectsProps = {
  projects: {
    color: string
    slug: string
    title: string
    service: string
    client: string
    cover: ChildImageSharp
  }[]
  [key: string]: any
}

const Projects = ({ projects }: ProjectsProps) => {
  const trail = useTrail(projects.length, {
    from: { height: `0%` },
    to: { height: `100%` },
  })

  const [visible, setVisible] = useState(false);
  setTimeout(() => {
    setVisible(true);
  }, 600);

  const [ctaVisible, setCtaVisible] = useState(false);
  setTimeout(() => {
    setCtaVisible(true);
  }, 1200);

  if (projects.length === 0) {
    return (
      <Layout>
        <Container>
          <Styled.p>
            Hi!{` `}
            <span role="img" aria-label="Wave emoji">
              👋
            </span>
            {` `}
            <br />
            Thanks for using <b>@lekoarts/gatsby-theme-emma</b>. You currently don't have any content in your{` `}
            <i>projects</i> folder - that's why this page displays a placeholder text. Head over to the{` `}
            <Styled.a href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-emma">
              README
            </Styled.a>
            {` `}
            to learn how to setup them.
          </Styled.p>
          <Styled.p>
            <b>TL;DR:</b> <br />
            The starter automatically created the folder <code>content/projects</code>. Go into this folder, create a
            new folder called <code>example</code> and create an <code>index.mdx</code> file there and place an image.
            Edit the frontmatter like described in the{` `}
            <Styled.a href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-emma">
              README
            </Styled.a>
            .
          </Styled.p>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      {/* Hero Section */}
      <div className="hero-wrapper">
        <div className="hero">
            <div className="heroText">
              <h1 className={`heroText-title ${visible ? "textVisible" : ""}`}>Hi, I'm Adrian,</h1>
              <p className={`heroText-sub ${visible ? "textVisible" : ""}`}>a digital product designer from Munich.<br/>I design with purpose, passion, and precision.</p>
              <div className={`hero-cta ${ctaVisible ? "textVisible" : ""}`}>
                <p>Projects</p>
                <svg className="hero-cta-arrow" viewBox="0 0 22 22">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.75 5V14.6893L15.9697 10.4697L17.0303 11.5303L11.0226 17.5381L4.49323 11.5529L5.50681 10.4471L10.25 14.7951V5H11.75Z"/>
                <path fill="none" fill-rule="evenodd" clip-rule="evenodd" d="M11 20.5C16.2467 20.5 20.5 16.2467 20.5 11C20.5 5.75329 16.2467 1.5 11 1.5C5.75329 1.5 1.5 5.75329 1.5 11C1.5 16.2467 5.75329 20.5 11 20.5ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"/>
                </svg>
              </div>
            </div>
        </div>
        </div>
      <div
        sx={{
          display: `grid`,
          gridTemplateColumns: `repeat(auto-fit, minmax(280px, 1fr))`,
          width: `100%`,
        }}>
        {trail.map((style, index) => (
          <ProjectItem style={style} node={projects[index]} key={projects[index].slug} />
        ))}
      </div>
    </Layout>
  )
}

export default Projects